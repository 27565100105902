/* eslint-disable no-unused-vars */
import Vue from "vue"
import App from "./App.vue"

import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import axios from "axios"
//import firebase from "firebase"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"

import settings from "./api/settings"
import { Console } from "@capacitor/core"
import "leaflet/dist/leaflet.css"
import { Icon } from "leaflet"

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
})

const { firebaseConfig, apiSettings } = settings
// Initialize Firebase
//firebase.initializeApp(firebaseConfig)
//auto login
/*
const token = localStorage.getItem("user-token");
if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}
*/

//let API_URL = "http://localhost:8080";
axios.defaults.baseURL = apiSettings.url

Vue.config.productionTip = false
import InlineSvg from "vue-inline-svg"
import Vuelidate from "vuelidate"
import "./registerServiceWorker"

Vue.use(Vuelidate)

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker)
Vue.component("inline-svg", InlineSvg)

new Vue({
  router,
  store,

  render: (h) => h(App)
}).$mount("#app")
