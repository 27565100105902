<template>
  <div>
    viewA
    <html-editor
      v-if="viewA == 'editor'"
      :data="{ height: this.screen_size.height, label: 'weekly report' }"
    />
    <a-map v-if="viewA == 'map'" />
  </div>
</template>

<script>
import HtmlEditor from "./HtmlEditor.vue"
import AMap from "./AMap.vue"
import { mapGetters } from "vuex"
export default {
  components: { HtmlEditor, AMap },
  computed: {
    ...mapGetters(["screen_size"]),
    viewA() {
      return this.$route.params.viewA ? this.$route.params.viewA : null
    }
  }
}
</script>

<style></style>
