import axios from "axios"
import store from "@/store"
const firebaseSetUp = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.auth}`,
    }
  }
  return axios
    .post("/signin-succes", payload, options)
    .then((response) => {
      return response.data
    })
    .catch(() => {
      return null
    })
}

const getUserWithToken = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
  console.log("token user", headers)
  return axios
    .get("/klaus_auth/token", { headers })
    .then((response) => {
      //console.log("response from token auth", response.data)
      if (response.status == 200) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
      }
      return response.data
    })
    .catch((/* response */) => {
      //acctions in case of error in server
      //console.log("rerrr", response)
      return null
    })
}
const validate = (payload, callBack) => {
  const { user } = store.getters
  const { role } = user ? user : {}
  console.log("validatoing ", role)
  if (role == "demo") {
    return {}
  } else {
    return callBack(payload)
  }
}
const updateAccount = (payload) => {
  return validate(payload, (payload) => {
    //body

    const headers = {
      "Content-Type": "application/json"
      //Authorization: `Bearer ${token}`,
    }

    return axios
      .post("/updateAccount", payload, {
        headers: headers
      })
      .then((response) => {
        //console.log("response from server", response.status, response.data)

        return response.data
      })
      .catch((/* response */) => {
        //acctions in case of error in server
        //console.log("rerrr", response)
        return null
      })
  })
}

const signup = (body) => {
  //body

  const headers = {
    "Content-Type": "application/json"
    //Authorization: `Bearer ${token}`,
  }

  return axios
    .post("/signup", body, {
      headers: headers
    })
    .then((response) => {
      //console.log("response from server", response.status, response.data)
      if (response.status == 200) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.auth_token}`
      }
      return response.data
    })
    .catch((/* response */) => {
      //acctions in case of error in server
      //console.log("rerrr", response)
      return null
    })
}

// ACA EL BODY SE VUELVE A FORMATEAR PARA QUE EL BACK FUNCIONE, PODRIA evitarse

const logIn = (account) => {
  //body
  const body = account
  const headers = {
    "Content-Type": "application/json"
    //Authorization: `Bearer ${token}`,
  }
  //console.log("LOGIN", JSON.stringify(account), body, headers)

  return axios
    .post("/klaus_auth", body, {
      headers: headers
    })
    .then((response) => {
      console.log("response from server", response.status)
      if (response.status == 200) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.auth_token}`
      }
      return response.data
    })
    .catch(() => {
      //acctions in case of error in server
      //console.log("rerrr", response)
      return null
    })
}
const getReport = () => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .get("/getReport", options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const updateReport = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .post("/updateReport", payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const getGeoListing = () => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .get("/getGeoListing", options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

export default {
  signup,
  logIn,
  getUserWithToken,
  getReport: (payload) => {
    return validate(payload, getReport)
  },
  updateReport: (payload) => {
    return validate(payload, updateReport)
  },
  getGeoListing: (payload) => {
    return validate(payload, getGeoListing)
  },
  updateAccount,
  firebaseSetUp
}
