<template>
  <div class="position-absolute d-flex flex-column">
    <head-bar />

    <div
      :class="`${
        $route.query.single || $route.query.listing ? '' : 'head-bar-visible'
      } bg-gray-light w-100 px-1 visible-nav-bar`"
      :style="{
        'overflow-x': 'hidden',
        'min-height': '100vh',
        width: '100vw !important'
      }"
    >
      <profile-view v-if="view == 'profile'" />
      <boxes-view v-if="view == 'boxes'" />
      <calendar-view v-if="view == 'calendar'" />
      <notifications-view v-if="view == 'notifications'" />
    </div>
    <nav-bar />
  </div>
</template>

<script>
import ProfileView from "../components/Account/ProfileView.vue"
export default {
  components: {
    "nav-bar": () => import("@/components/Layout/NavBar"),
    "head-bar": () => import("@/components/Layout/HeadBar"),
    "boxes-view": () => import("@/components/Boxes/BoxesView"),
    "calendar-view": () => import("@/components/Calendar/CalendarView"),
    "notifications-view": () =>
      import("@/components/Notifications/NotificationsView"),
    ProfileView
  },
  computed: {
    view() {
      //return this.$route.params.view ? this.$route.params.view : "boxes"
      if (this.$route.params.view) {
        return this.$route.params.view
      } else {
        return "boxes"
      }
    }
  }
}
</script>

<style></style>
