<template>
  <div class="overflow-hidden position-relative">
    <label
      v-if="label && typeof label == 'string'"
      class="text-start me-auto"
      v-html="label"
    />

    <editor
      ref="editorInstance"
      :apiKey="'46h77p8lm9ahc97ig8e6lh0zq3danjqruv7i5v2j8p2le9gb'"
      :init="tinyMCEconfig"
      :value="fieldValue"
      @input="updateFieldValue"
      @onKeyDown="escapeKeyDown"
    />
    <button
      @click="saveReport"
      class="
        my-3
        mx-3
        h-max-content h-3-5-r
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        justify-content-between
        text-grey-medium
        w-auto
      "
    >
      <span class="mx-3 align-self-center">Guardar db</span>
      <div class="align-self-center"></div>
    </button>
    <button
      @click="
        $router.push({
          name: 'Admin',
          params: { viewA: undefined },
          query: {}
        })
      "
      class="
        my-3
        mx-3
        h-max-content h-3-5-r
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        justify-content-between
        text-grey-medium
        w-auto
      "
    >
      <span class="mx-3 align-self-center">CANCELAR</span>
      <div class="align-self-center"></div>
    </button>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue"
import FormField from "@/mixins/form-field.js"
import { mapGetters, mapMutations, mapActions } from "vuex"
export default {
  name: "HtmlEditor",
  props: ["data", "viewerParam", "label", "height"],
  mixins: [FormField],
  data() {
    const tinyMCEconfig = {
      plugins:
        "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",

      menubar: "file edit view insert format tools table help",
      toolbar:
        "undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
      toolbar_sticky: true,
      image_advtab: true,
      //importcss_append: true,
      //image_caption: true,
      quickbars_selection_toolbar:
        "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
      toolbar_mode: "sliding",
      contextmenu: "link image imagetools table",
      skin: "oxide",
      /* content_css:
        "https://static.klaus.market/" + eCommerceDomain + "/styles/bundle.css",
      content_css_cors: true, */
      force_p_newlines: false,
      forced_root_block: "",
      height: this.height || 300
    }
    return {
      tinyMCEconfig
    }
  },

  components: {
    editor: Editor
  },
  computed: {
    ...mapGetters("admin", ["report"]),
    fieldValue: {
      set(value) {
        this.UPDATE_REPORT({ value, key: "body" })
      },
      get() {
        return this.report && this.report.body ? this.report.body : ""
      }
    }
  },
  methods: {
    ...mapMutations("admin", ["UPDATE_REPORT"]),
    ...mapActions("admin", ["SAVE_REPORT"]),

    updateFieldValue(event) {
      console.log("value html", event)
      this.UPDATE_REPORT({ value: event, key: "body" })
    },
    async saveReport() {
      const rta = await this.SAVE_REPORT()
      if (rta) {
        this.$router.push({
          name: "Admin",
          params: { viewA: undefined },
          query: {}
        })
      }
    },
    escapeKeyDown(e) {
      if (e.keyCode == 27) {
        console.log("EDITOR INSTANCE", this.$refs.editorInstance)
        /*let editor = this.$refs.editorInstance.editor
         const dom = editor.dom
        const parentBlock = editor.selection.getSelectedBlocks()[0]
        const containerBlock =
          parentBlock.parentNode.nodeName == "BODY"
            ? dom.getParent(parentBlock, dom.isBlock)
            : dom.getParent(parentBlock.parentNode, dom.isBlock)
        let newBlock = editor.dom.create("p")
        newBlock.innerHTML = '<br data-mce-bogus="1">'
        dom.insertAfter(newBlock, containerBlock)
        let rng = dom.createRng()
        newBlock.normalize()
        rng.setStart(newBlock, 0)
        rng.setEnd(newBlock, 0)
        editor.selection.setRng(rng) */
      }
    }
  }
}
</script>
