<template>
  <div
    @click="$router.push({ name: 'Login' })"
    class="d-flex flex-column align-items-center font-rem-0-8"
  >
    Introduce tus <br />
    credenciales :
    <inline-svg
      src="/flecha-ladoder.svg"
      width="45px"
      height="45px"
      fill="white"
      aria-label="Loading..."
    />
  </div>
</template>
<script>
export default {}
</script>
