<template>
  <div class="h-100">
    <transition name="fade" mode="out-in">
      <div
        v-if="!logged"
        class="position-absolute d-flex align-items-center bg-green"
        style="width: 100%; height: 100%; left: 0; top: 0"
      >
        <div class="container mb-5">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 mb-4">
              <img
                src="/V-logo-verdeoscuro.png"
                width="100%"
                aria-label="wSeeds logo"
              />
            </div>
          </div>
          <div v-if="!$route.query.new" class="row justify-content-center">
            <div
              class="
                col-10 col-md-6 col-xl-4
                bg-white
                rounded
                p-4 p-md-5
                shadow
                d-flex
                flex-column
                align-items-center
              "
            >
              <span class="d-block mb-3 brown-green-bold font-rem-1-25"
                >Ingresa a tu cuenta</span
              >
              <label class="mb-3 w-100">
                <!-- <span class="d-block mb-2">Correo electrónico</span> -->
                <input
                  class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
                  v-model="user_name"
                  placeholder="Celular ó Correo electrónico"
                  @keyup.enter="$refs.pass.focus()"
                  autofocus
                />
              </label>

              <label class="mb-3 w-100">
                <input
                  class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
                  type="password"
                  ref="pass"
                  v-model="password"
                  placeholder="Contraseña"
                  @keyup.enter="
                    () => {
                      if (!forgotP) {
                        logIn()
                      } else {
                        $refs.pass2.focus()
                      }
                    }
                  "
                />

                <transition-expanded>
                  <div v-if="forgotP">
                    <div class="py-2">
                      <input
                        class="w-100 brown-wGreyMedium"
                        type="password"
                        ref="pass2"
                        v-model="passVerified"
                        placeholder="Repeat password"
                      />
                    </div>
                  </div>
                </transition-expanded>
              </label>
              <div
                class="
                  w-100
                  font-rem-0-8
                  d-flex
                  flex-row
                  justify-content-between
                  brown-wGreyMedium
                "
              >
                <div class="d-flex flex-row mx-2">
                  <input class="align-self-center" type="checkbox" />
                  <span class="mx-1">Recuérdame</span>
                </div>
                <div class="">
                  <div
                    class="text-decoration-underline"
                    @click="forgotP = !forgotP"
                  >
                    olvide mi contraseña
                  </div>
                </div>
              </div>
            </div>

            <button
              v-if="!$route.query.new"
              class="
                py-2
                px-3
                mt-3
                w-75
                h-50
                rounded
                bg-dark-green
                shadow-sm
                pointer
                d-flex
                justify-content-center
                text-white
                w-fit-content
              "
              @click="logIn"
            >
              <span class="align-self-center"> Ingresar </span>
            </button>
            <div
              v-if="!$route.query.new"
              class="d-flex justify-content-center brown-white-regular mx-1"
            >
              <span>No tienes cuenta?</span
              ><span
                class="mx-2 text-decoration-underline brown-green-dark-regular"
                @click="$router.push({ query: { new: true } })"
                >Crea una</span
              >
            </div>
          </div>
          <div v-else-if="$route.query.new" class="row justify-content-center">
            <account-info />
            <div
              class="
                d-flex
                justify-content-center
                brown-white-regular
                my-3
                mx-1
              "
            >
              <span>Ya tienes una cuenta?</span
              ><span
                class="mx-2 text-decoration-underline brown-green-dark-regular"
                @click="$router.push({ query: {} })"
                >ingresa aquí</span
              >
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//import firebase from "firebase"
import settings from "@/api/settings"
import notifications from "@/mixins/notifications"

import { mapActions, mapGetters } from "vuex"
export default {
  mixins: [notifications],
  components: {
    "transition-expanded": () => import("@/components/TransitionExpanded"),
    "account-info": () => import("@/components/Account/AccountInfo")
  },
  data() {
    return {
      forgotP: false,
      logged: false,
      user_name: "",
      password: "",
      firebasePublicKey: settings.firebasePublicKey,
      passVerified: ""
    }
  },
  computed: {
    ...mapGetters(["device"])
  },
  methods: {
    ...mapActions(["AUTH_REQUEST", "SUCCES_IN", "SIGN_UP"]),
    logIn() {
      let account = {
        username: this.user_name,
        password: this.password
      }
      const { platform } = this.device
      //console.log("LOGIN", account)
      this.AUTH_REQUEST(account)
        .then(() => {
          //console.log("REDIRECTED FROM SUCCES");
          this.$router.push({
            name: "View",
            params: {
              view: "boxes"
            }
          })
          if (platform != "web") {
            this.ngOnInit()
          }
        })
        .catch(() => {
          this.error_alert = true
        })
    }
  }
}
</script>

<style></style>
