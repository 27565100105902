<template>
  <div class="container">
    <div v-if="$route.query && !$route.query.mode">
      <profile-options />
    </div>
    <div v-else-if="$route.query.mode == 'view'">
      <profile-template />
    </div>
    <div
      v-else-if="$route.query.mode == 'edit'"
      class="pt-4 row justify-content-center"
    >
      <div class="align-self-center d-flex">
        <inline-svg
          @click="$router.push({ query: { mode: 'view' } })"
          class="align-self-start t-rotate-180"
          src="/flecha-ladoder.svg"
          width="45px"
          height="45px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
      <account-info />
    </div>
  </div>
</template>

<script>
import ProfileOptions from "./ProfileOptions.vue"
import ProfileTemplate from "./ProfileTemplate.vue"
export default {
  components: {
    ProfileOptions,
    ProfileTemplate,
    "account-info": () => import("@/components/Account/AccountInfo")
  }
}
</script>

<style></style>
