<template>
  <div class="position-absolute d-flex flex-column">
    <head-bar />
    <div
      :class="`${
        $route.query.single || $route.query.listing ? '' : 'head-bar-visible'
      } bg-gray-light w-100 px-1`"
      :style="{
        'overflow-x': 'hidden',
        'min-height': '100vh',
        width: '100vw !important'
      }"
    >
      <div
        class="
          h-100
          py-5
          px-2
          d-flex
          flex-column
          justify-content-around
          align-items-center
        "
      >
        <div
          class="container d-flex flex-column px-3 justify-content-around"
          :style="{
            height: '100vh !important'
          }"
        >
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 mb-4">
              <img
                src="/V-logo-verdeoscuro.png"
                width="100%"
                aria-label="wSeeds logo"
              />
            </div>
          </div>
          <div
            class="
              py-2
              px-3
              mt-3
              w-75
              rounded
              bg-dark-green
              shadow-sm
              pointer
              d-flex
              flex-column
              justify-content-center
              text-white
              w-fit-content
              align-self-center
            "
          >
            <div class="align-self-center font-rem-2">
              Proximamente mas info en :
            </div>
            <br />
            <div class="align-self-center font-rem-2">{{ countString }}</div>
          </div>
          <div
            class="
              px-4
              pt-4
              pb-4
              my-5
              mx-5
              h-max-content
              brown-white-bold
              bg-black-light
              rounded
              w-75
              align-self-center
            "
          >
            <p>Usuario registrado?</p>
            <div class="d-flex align-items-center justify-content-around">
              <app-store />
              <google-play />
              <web-app />
              <contactanos />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileView from "../components/Account/ProfileView.vue"
import Contactanos from "../components/Layout/Logos/Contactanos.vue"
import GooglePlay from "../components/Layout/Logos/GooglePlay.vue"
import WebApp from "../components/Layout/Logos/WebApp.vue"

export default {
  components: {
    "nav-bar": () => import("@/components/Layout/NavBar"),
    "head-bar": () => import("@/components/Layout/HeadBar"),
    "boxes-view": () => import("@/components/Boxes/BoxesView"),
    "calendar-view": () => import("@/components/Calendar/CalendarView"),
    "notifications-view": () =>
      import("@/components/Notifications/NotificationsView"),
    ProfileView,
    "app-store": () => import("@/components/Layout/Logos/AppStore.vue"),
    GooglePlay,
    WebApp,

    Contactanos
  },
  data() {
    const countDownDate = new Date("Aug 16, 2021 16:20:00").getTime()
    this.setTimerDown()
    return {
      countDownDate,
      distance: 100,
      countString: ""
    }
  },
  mounted() {
    // this.setTimerDown()
  },
  computed: {
    view() {
      const boxesOrHome = this.$route.path == "/" ? "home" : "boxes"
      return this.$route.params.view ? this.$route.params.view : boxesOrHome
    }
  },
  methods: {
    setTimerDown() {
      setInterval(
        function () {
          // Get today's date and time
          let now = new Date().getTime()

          // Find the distance between now and the count down date
          this.distance = this.countDownDate - now

          // Time calculations for days, hours, minutes and seconds
          let days = Math.floor(this.distance / (1000 * 60 * 60 * 24))
          let hours = Math.floor(
            (this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
          let minutes = Math.floor(
            (this.distance % (1000 * 60 * 60)) / (1000 * 60)
          )
          let seconds = Math.floor((this.distance % (1000 * 60)) / 1000)

          // Display the result in the element with id="demo"
          this.countString =
            days + "d " + hours + "h " + minutes + "m " + seconds + "s "

          // If the count down is finished, write some text
          if (this.distance < 0) {
            this.countString = "EXPIRED"
          }
        }.bind(this),
        1000
      )
    }
  }
}
</script>

<style></style>
