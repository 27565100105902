import { Geolocation } from "@capacitor/geolocation"
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["loading", "device"])
  },
  methods: {
    getGeoRef(latitude, longitude) {
      const payload = {
        field: "geoReference",
        value: {
          status: "unpublished",
          longitude: null,
          latitude: null,
          point: {
            type: "Point",
            coordinates: []
          }
        }
      }
      const point = {
        type: "Point",
        coordinates: [longitude, latitude]
      }
      return Object.assign(payload, {
        value: { longitude, latitude, point }
      })
    },
    async getPosition() {
      console.log("getCurrentPosition")

      const position = await Geolocation.getCurrentPosition()
      console.log("GET_CURRENT", position)
      if (position) {
        const { latitude, longitude } = position.coords

        return this.getGeoRef(latitude, longitude)
      } else {
        const latitude = 4.7559
        const longitude = -74.052555
        return this.getGeoRef(latitude, longitude)
      }
    },
    getWebLocation() {
      if (navigator.geolocation) {
        const payload = {
          field: "geoReference",
          value: {
            status: "unpublished",
            longitude: null,
            latitude: null,
            point: {
              type: "Point",
              coordinates: []
            }
          }
        }
        const nPro = new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              console.log("mounted pos", pos)
              /*  const {
                      accuracy,
                      altitude,
                      altitudeAccuracy,
                      heading,
                      latitude,
                      longitude,
                      speed,
                    } = pos */

              const { latitude, longitude } = pos.coords
              const point = {
                type: "Point",
                coordinates: [longitude, latitude]
              }

              resolve(
                Object.assign(payload, {
                  value: { longitude, latitude, point }
                })
              )
            },
            (err) => {
              // se deve validar la localizacion
              const latitude = 4.7559
              const longitude = -74.052555

              const point = {
                type: "Point",
                coordinates: [longitude, latitude]
              }
              resolve(
                Object.assign(payload, {
                  value: {
                    longitude,
                    latitude,
                    point,
                    err: err.message
                  }
                })
              )
            }
          )
        })
        return nPro
      } else return null
    },
    navAPi() {
      console.log("this.device", this.device)
      if (this.device && this.device.platform == "web") {
        return this.getWebLocation()
      } else if (
        this.device &&
        (this.device.platform == "ios" || this.device.platform == "android")
      ) {
        return this.getPosition()
      } else return null
    }
  }
}
