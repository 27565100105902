import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Admin from "../views/Admin.vue"
import WebHome from "../views/WebHome.vue"
import Login from "../views/Login.vue"
import Contact from "../views/Contact.vue"
import Privacy from "../views/Privacy.vue"
import Map from "../views/Map.vue"
import store from "../store"
Vue.use(VueRouter)

/* const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next("/")
}
*/
const initApp = (to, from, next) => {
  store.dispatch("INIT_APP").then((rRR) => {
    console.log("initAPp try", rRR)
    const { /*  wAUTH, */ device } = store.getters
    //const isAuth = wAUTH ? true : false
    const isNativeApp =
      device && device.platform && device.platform != "web" ? true : false
    const outBounds = to.name == "Home"

    if (/* isAuth && */ isNativeApp && outBounds) {
      next({ name: "View", params: { view: "boxes" } })
    } else next()
  })
}
const auth_guard = (to, from, next) => {
  const { wAUTH } = store.getters
  //console.log("auth guard", to, from, store, wAUTH)

  if (wAUTH) next()
  else next({ name: "Login" })
}
const auth_guard_admin = (to, from, next) => {
  const { wAUTH, user } = store.getters
  console.log("auth guard aadmin", user, wAUTH)

  if (wAUTH) {
    if (user && user.role == "admin") {
      next()
    } else {
      next({ name: "View" })
    }
  } else {
    next({ name: "Login" })
  }
}
const routes = [
  {
    path: "/",
    name: "Home",
    component: WebHome
  },
  {
    path: "/contacto",
    name: "Contact",
    component: Contact
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/app/:view",
    name: "View",
    component: Home,
    beforeEnter: auth_guard
  },
  {
    path: "/admin",
    //name: "Admin",
    component: Admin,
    beforeEnter: auth_guard_admin
  },
  {
    path: "/admin/:viewA",
    name: "Admin",
    component: Admin,
    beforeEnter: auth_guard_admin
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/dev/",
    name: "dev",
    component: Map
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(initApp)

export default router
