import axios from "axios"

import store from "@/store"
const validate = (payload, callBack) => {
  const { user } = store.getters
  const { role } = user ? user : {}

  if (role == "demo") {
    return {}
  } else {
    return callBack(payload)
  }
}
const registerBox = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }

  return axios
    .post("/boxes/register", payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const sendMessage = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .post("/wapp/contact", payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const updateBox = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .post("/boxes/update", payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const deleteBox = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .post("/boxes/delete", payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const updateNotifications = (payload) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .post("/box/notifications", payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}
const getRange = (payload, singleBox = null) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  const queryString = singleBox ? `?singleBox=${singleBox}` : ""
  return axios
    .post("/measurements/range" + queryString, payload, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}
const getObjects = (collection) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .get(collection, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

const getMTypes = () => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .get("/box_measurements", options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}
const getBoxes = () => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .get("/boxes", options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}
const filterNotifications = (body) => {
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
      //Authorization: `Bearer ${state.token}`,
    }
  }
  return axios
    .post("/notifications/filter", body, options)
    .then((response) => {
      return response.data
    })
    .catch((/* response */) => {
      return null
    })
}

export default {
  registerBox: (payload) => {
    return validate(payload, registerBox)
  },
  updateNotifications: (payload) => {
    return validate(payload, updateNotifications)
  },
  getRange,
  getObjects,
  getMTypes,
  getBoxes,
  filterNotifications,
  updateBox: (payload) => {
    return validate(payload, updateBox)
  },
  sendMessage,
  deleteBox: (payload) => {
    return validate(payload, deleteBox)
  }
}
