import { mapActions } from "vuex"

import * as Validators from "vuelidate/lib/validators"

export default {
  props: ["label", "data", "child", "kRefs", "validations"],

  mounted() {
    //console.log("MONTANDO FORMFIELD", this.$v, this.data.target)

    //console.log("CHECKING FORMFIELD MOUNT", this.dirtyForm)
    // start up validations library
    this.updateValidations()
    if (this.dirtyForm) {
      this.$v.$touch()
    }
  },
  computed: {
    evaluatedTarget() {
      return this.getValue(this.data.target)
    },
    evaluatedProps() {
      const availableProps =
        this.child && this.child.props ? this.child.props : false

      return availableProps
        ? this.evaluateCoordinateKeys(availableProps)
        : false
    },
    evaluatedAttrs() {
      const availableAttrs =
        this.child && this.child.attrs ? this.child.attrs : false

      return availableAttrs
        ? this.evaluateCoordinateKeys(availableAttrs)
        : false
    },

    fieldValue() {
      const multipleSelection = this.evaluatedProps.multiple
      const optionsAvailable =
        this.evaluatedProps && this.evaluatedProps.options
          ? this.evaluatedProps.options
          : false
      const trackerAvailable =
        this.evaluatedProps && this.evaluatedProps.trackBy
          ? this.evaluatedProps.trackBy
          : false

      const validFieldValue = this.evaluatedTarget
        ? optionsAvailable && trackerAvailable
          ? multipleSelection
            ? optionsAvailable.filter((option) =>
                this.evaluatedTarget.includes(option[trackerAvailable])
              )
            : optionsAvailable.find(
                (option) => option[trackerAvailable] == this.evaluatedTarget
              )
          : this.evaluatedTarget
        : undefined

      /* console.log("CHECKING VALUE")
      console.table({
        optionsAvailable,
        multipleSelection,
        trackerAvailable,
        validFieldValue
      }) */

      return validFieldValue
    },
    errorMessage() {
      const validations = this.$v.fieldValue

      const validationKeys =
        this.validations && this.isPlainObject(this.validations)
          ? Object.keys(this.validations)
          : []

      const finalValidations = validationKeys.map((validationKey) => {
        const validar = validations[validationKey]
        return this.getError(validar, validationKey)
      })

      let finalMessage = finalValidations.find((validation) => {
        return validation !== undefined
      })

      return finalMessage
    },
    dirtyForm() {
      if (this.data.target) {
        const { param } = this.data.target
        const route = this.$store.getters["validationsIndex"]({
          param: "dirty",
          key: param ? this.getValue(param) : undefined
        })
        const isDirty = route ?? false
        //console.log("IS DIRTY?", route, this.data.target, isDirty)
        return isDirty
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions(["UPDATE_validations"]),
    validationRules(validations) {
      const validationKeys = Object.keys(validations).filter((option) => {
        //console.log("PARSER", validations, validationKeys)
        return option !== "util" && option !== "debug"
      })
      let finalValidation = {}

      validationKeys.map((validationKey) => {
        const keyParams = this.getValue(validations[validationKey].param)
        const param = keyParams
          ? validationKey == "sameAs"
            ? () => keyParams
            : keyParams
          : null
        if (param) {
          finalValidation[validationKey] = Validators[validationKey](param)
        } else {
          finalValidation[validationKey] = Validators[validationKey]
        }
        //console.log("PARAMS LOOP", param)
        return true
      })

      //console.log("FINAL FALIDATION", finalValidation)

      return finalValidation
    },
    async updateFieldValue(event) {
      const payload = {
        param: this.getValue(this.data.target.param) ?? null,
        parent: this.getValue(this.data.target.parent) ?? null,
        key: this.getValue(this.data.target.key) ?? null,
        dig: this.data.target.dig ?? null,
        value: !event
          ? null
          : this.data.target.value && this.data.target.value.source
          ? this.evaluatedProps.multiple
            ? event.reduce(
                (accumulator, current) => [
                  ...accumulator,
                  this.getValue(this.data.target.value, current)
                ],
                []
              )
            : this.getValue(this.data.target.value, event)
          : this.isPlainObject(event) && event._id
          ? event
          : this.data.component == "switcher"
          ? event.target.checked
          : event.target
          ? event.target.value
          : event
      }
      /* console.log(
        "UPDATING FORMFIELD VALUE",
        event,
        payload,
        this.data.target.module
          ? this.data.target.module + "/" + this.data.target.action
          : this.data.target.action
      ) */
      this.$store.dispatch(this.data.target.action, payload)

      const inputComponent = this.$refs.inputComponent

      //console.log("VALUE BEFORE", inputComponent, payload.value)

      await this.$nextTick(() => {
        //console.log("VALUE AFTER", inputComponent, payload.value)

        if (
          this.child &&
          this.child.component !== "vue-multiselect" &&
          this.child.component !== "checkbox-list" &&
          inputComponent &&
          inputComponent.value &&
          inputComponent.value != payload.value
        ) {
          inputComponent.value = payload.value
          // Do other stuff here
        }

        this.$emit("input", payload)

        if (this.$v) {
          this.$v.$touch()
          this.updateValidations()
        }
      })
    },
    getError(validation, validationKey) {
      if (!validation) {
        return this.getValue(this.validations[validationKey].message)
      }
    },
    updateValidations() {
      if (this.data && this.data.target) {
        const { param, parent, key } = this.data.target
        this.UPDATE_validations({
          source: "store",
          param: param ? this.getValue(param) : undefined,
          parent: parent ? this.getValue(parent) : undefined,
          key: key ? this.getValue(key) : undefined,
          value: !this.$v.$invalid
        })
      }
      //console.log("UPDATING VALUIDATIONS", !this.$v.$invalid)
    }
  },
  watch: {
    dirtyForm() {
      //const { param } = this.data.target
      //console.log("CAMBIANDO DIRTY FORM", this.isDirty, this.target)
      if (this.dirtyForm) this.$v.$touch()
      //this.UPDATE_STATE({ param: "dirty", key: param, value: this.dirtyForm })
    },
    fieldValue() {
      const inputComponent = this.$refs.inputComponent

      if (
        this.child &&
        this.child.component !== "vue-multiselect" &&
        this.child.component !== "checkbox-list" &&
        inputComponent &&
        inputComponent.value &&
        inputComponent.value != this.fieldValue
      ) {
        /* console.log(
          "VALUE AVOIDING SIDE EFFECT",
          inputComponent,
          this.fieldValue
        ) */
        this.updateFieldValue(this.fieldValue)
        // Do other stuff here
        this.$v.$touch()
        this.updateValidations()
      }
    }
  },
  validations() {
    //console.log("Validating", this.validations)
    if (this.validations) {
      return {
        fieldValue: this.validationRules(this.validations)
      }
    } else {
      return {
        fieldValue: {}
      }
    }
  }
}
