<template>
  <div
    @click="$router.push({ name: 'Contact' })"
    class="d-flex flex-column align-items-center font-rem-0-8"
  >
    Contáctanos:
    <img
      class="align-self-center"
      src="/contact_us.png"
      aria-label="Loading..."
      width="67px"
    />
  </div>
</template>
<script>
export default {}
</script>
