<template>
  <div class="h-100">
    <transition name="fade" mode="out-in">
      <div
        class="position-absolute d-flex align-items-center bg-green"
        style="width: 100%; height: 100%; left: 0; top: 0"
      >
        <div class="container mb-5">
          <div class="row justify-content-center">
            <div class="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 mb-4">
              <img
                src="/V-logo-verdeoscuro.png"
                width="100%"
                aria-label="wSeeds logo"
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="
                col-10 col-md-6 col-xl-4
                bg-white
                rounded
                p-4 p-md-5
                shadow
                d-flex
                flex-column
                align-items-center
              "
            >
              <span class="d-block mb-3 brown-green-bold font-rem-1-25"
                >Preguntas, recomendaciones, ¿sugerencias?, Contáctanos</span
              >
              <label class="mb-3 w-100">
                <!-- <span class="d-block mb-2">Correo electrónico</span> -->
                <input
                  class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
                  v-model="name"
                  placeholder="Nombre"
                />
              </label>
              <label class="mb-3 w-100">
                <!-- <span class="d-block mb-2">Correo electrónico</span> -->
                <input
                  class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
                  v-model="email"
                  placeholder="Correo electrónico"
                />
              </label>
              <label class="mb-3 w-100">
                <!-- <span class="d-block mb-2">Correo electrónico</span> -->
                <input
                  class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
                  v-model="phone"
                  placeholder="Celular"
                />
              </label>

              <label class="mb-3 w-100">
                <textarea
                  class="w-100 brown-wGreyMedium font-rem-0-8 rounded border"
                  v-model="message"
                  rows="5"
                  placeholder="Cuentanos.."
                />
              </label>
            </div>

            <button
              class="
                py-2
                px-3
                mt-3
                w-75
                h-50
                rounded
                bg-dark-green
                shadow-sm
                pointer
                d-flex
                justify-content-center
                text-white
                w-fit-content
              "
              @click="sendMessage()"
            >
              <span class="align-self-center"> Enviar </span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//import firebase from "firebase"

import notifications from "@/mixins/notifications"
import utilsAPi from "@/api/utils"
import { mapActions, mapMutations } from "vuex"
export default {
  mixins: [notifications],
  components: {
    "transition-expanded": () => import("@/components/TransitionExpanded")
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: ""
    }
  },
  methods: {
    ...mapActions(["AUTH_REQUEST", "SUCCES_IN", "SIGN_UP"]),
    ...mapMutations(["LOADING"]),
    async sendMessage() {
      const obj = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message
      }
      console.log("before send", obj)
      this.LOADING(true)
      const rta = await utilsAPi.sendMessage(obj)
      if (rta) {
        this.LOADING(false)
        this.$router.push({ name: "Login" })
      }
    }
  }
}
</script>

<style></style>
