<template>
  <div class="py-5 px-2 d-flex flex-column">
    <div class="d-flex justify-content-between">
      <div class="align-self-center" @click="$router.push({ query: {} })">
        <inline-svg
          class="align-self-center t-rotate-180"
          src="/flecha-ladoder.svg"
          width="45px"
          height="45px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
      <div
        class="align-self-center"
        @click="$router.push({ query: { mode: 'edit' } })"
      >
        <inline-svg
          class="align-self-center "
          src="/configuracion.svg"
          width="30px"
          height="30px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
    </div>
    <div
      class="px-2 my-3 mx-4 h-max-content brown-wGreyMedium rounded bg-white shadow-sm pointer d-flex flex-column justify-content-between  text-grey-medium  w-auto"
    >
      <span class="py-3 mx-3 align-self-center">Nombre: {{ user.name }}</span>

      <span class="py-3 mx-3 align-self-center">Celular: {{ user.phone }}</span>

      <span class="py-3 mx-3 align-self-center"
        >Correo electrónico: {{ user.email }}</span
      >

      <span class="py-3 mx-3 align-self-center">Contraseña: *******</span>

      <span class="py-3 mx-3 align-self-center"
        >Empresa: {{ user.company }}</span
      >
      <div class="align-self-center"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(["user"])
  }
}
</script>

<style></style>
