<template>
  <div>
    <div style="height: 80vh; width: 100%'">
      <div id="map-wrap" class="h-100">
        <l-map
          ref="myMap"
          :zoom.sync="zoom"
          :center="[position.latitude, position.longitude]"
          @ready="doSomethingOnReady()"
          @click="clickMap"
        >
          <!-- <l-map
        ref="myMap"
        :zoom.sync="zoom"
        :center="[position.latitude, position.longitude]"
        @ready="doSomethingOnReady()"
        @click="clickMap"
      > -->
          <l-tile-layer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          ></l-tile-layer>
          <!-- <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        ></l-tile-layer> -->
          <l-marker
            v-if="customMarket.latitude && customMarket.longitude"
            :lat-lng="[customMarket.latitude, customMarket.longitude]"
          ></l-marker>
        </l-map>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
//import L from "leaflet"
import geoLocalization from "@/mixins/geoLocalization"
import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
import { latLng /* , Icon, icon */ } from "leaflet"
export default {
  props: ["box"],
  data() {
    return {
      position: {
        latitude: 4.822786,
        longitude: -74.046872
      },
      map: null,
      mapApi: null,
      latLng,
      zoom: 13,
      draggable: false,
      popupContent: "KLaius",
      searching: false,
      iconSize: 70,
      searchResult: [],
      geoPosition: null,
      customMarket: {
        /*  latitude: 1.1,
        longitude: 1.2 */
      },
      distance: 1000
    }
  },
  mixins: [geoLocalization],
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  methods: {
    setNewLocation() {
      this.$emit("setLocation", this.geoPosition)
    },
    async center() {
      const navigatorApiRes = await this.navAPi()
      console.log("nav response", navigatorApiRes, this.$refs.myMap.mapObject)
      this.zoom = 10
      const { latitude, longitude } = navigatorApiRes
      this.customMarket = {
        latitude,
        longitude
      }
      this.geoPosition = navigatorApiRes
    },
    doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject
      console.log("HEEllo map", this.map)
      if (this.box.geoReference) {
        const { latitude, longitude } = this.box.geoReference
        this.geoPosition = this.box.geoReference
        this.customMarket = {
          latitude,
          longitude
        }
      }
    },
    clickMap(v) {
      console.log("Click", v)
      const { latlng } = v
      if (latlng && latlng.lat && latlng.lng) {
        // se actualiza el store con esta info
        const payload = {
          field: "geoReference",

          value: {
            latitude: latlng.lat,
            longitude: latlng.lng,
            point: {
              type: "Point",
              coordinates: [latlng.lng, latlng.lat]
            }
          }
        }
        this.customMarket = {
          latitude: latlng.lat,
          longitude: latlng.lng
        }
        this.geoPosition = payload.value
      }
    }
  }
}
</script>

<style></style>
