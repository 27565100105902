import Vue from "vue"
import Vuex from "vuex"
import general from "./modules/general"
import admin from "./modules/admin"
import boxes from "./modules/boxes"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { general, boxes, admin }
})
