import Vue from "vue"
import utilsApi from "@/api/utils"
const getInitial = () => {
  return {
    start: null,
    end: null
  }
}
const state = getInitial()

const getters = {
  boxState: (state) => (serial) => {
    if (serial && state[serial]) {
      const box = state[serial]
      return box.status == "online" ? true : false
    } else {
      return false
    }
  },
  start: (state) => state.start,
  end: (state) => state.end,
  boxBySerial: (state) => (serial) => {
    //console.log("SERIAAL", state, serial)
    if (serial && state[serial]) {
      return state[serial]
    } else {
      return false
    }
  }
}

const actions = {
  async DELETE_BOX({ commit, dispatch }, payload) {
    commit("LOADING", true, { root: true })
    const apiRes = await utilsApi.deleteBox(payload)
    if (apiRes) {
      await dispatch("getNotifications", null, { root: true })
      await dispatch("GET_OBJECTS", "measurements", { root: true })
      commit("LOADING", false, { root: true })
      return true
    } else {
      commit("LOADING", false, { root: true })
      return null
    }
  },
  async UPDATE_BOX({ commit, dispatch }, payload) {
    commit("LOADING", true, { root: true })
    const apiRes = await utilsApi.updateBox(payload)
    if (apiRes) {
      await dispatch("getNotifications", null, { root: true })
      await dispatch("GET_OBJECTS", "measurements", { root: true })
      commit("LOADING", false, { root: true })
      return true
    } else {
      commit("LOADING", false, { root: true })
      return null
    }
  },
  async REGISTER_BOX({ commit, dispatch }, payload) {
    commit("LOADING", true, { root: true })
    const apiRes = await utilsApi.registerBox(payload)
    if (apiRes) {
      await dispatch("getNotifications", null, { root: true })
      await dispatch("GET_OBJECTS", "measurements", { root: true })
      commit("LOADING", false, { root: true })
      return true
    } else {
      commit("LOADING", false, { root: true })
      return null
    }
  },
  async updatePort({ commit, dispatch }, payload) {
    commit("LOADING", true, { root: true })
    const apiRes = await utilsApi.updateNotifications(payload)
    if (apiRes) {
      await dispatch("getNotifications", null, { root: true })
      await dispatch("GET_OBJECTS", "measurements", { root: true })
      commit("LOADING", false, { root: true })
    } else {
      return null
    }
  },
  async getRange({ commit }, payload) {
    commit("LOADING", true, { root: true })
    const isSingleBox = payload.singleBox ?? null
    const apiResponse = await utilsApi.getRange(
      {
        start: payload.start,
        end: payload.end
      },
      isSingleBox
    )
    if (apiResponse) {
      commit("SET_BOXES", apiResponse.boxes)
      commit("SET_RANGE", apiResponse)
      commit("LOADING", false, { root: true })
    } else {
      console.log("Not Error")
    }
  }
}

const mutations = {
  RST: (state) => {
    const init = getInitial()
    const keyIni = Object.keys(init)
    const keyState = Object.keys(state)
    Object.assign(state, getInitial())
    keyState.map((kS) => {
      if (!keyIni.find((kI) => kI == kS)) {
        delete state[kS]
      }
    })
  },
  UPDATE_BOX_PORT: (state, payload) => {
    /* console.log(
      "UPDATE_BOX_PORT",
      payload,
      payload.serial,
      state[payload.serial]
    ) */
    const nPort = state[payload.serial].port_data.map((p) => {
      if (p.label == payload.label) {
        const update = {}
        update[payload.key] = payload.value
        return Object.assign({}, p, update)
      } else return p
    })
    Vue.set(state[payload.serial], "port_data", nPort)
  },
  SET_RANGE: (state, payload) => {
    state.start = payload.start
    state.end = payload.end
  },
  SET_BOXES: (state, boxes) => {
    boxes.map((box) => {
      Vue.set(state, box.serial, box)
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
