<template>
  <div id="app">
    <!-- <div id="nav"></div> -->
    <layer />
    <inline-svg
      v-if="$route.query.serial"
      class="position-fixed backButton align-self-center t-rotate-180 z-100"
      src="/flecha-ladoder.svg"
      width="45px"
      height="45px"
      fill="white"
      aria-label="Loading..."
      @click="$router.push(-1)"
    />
    <router-view />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

import notifications from "@/mixins/notifications"
import device from "@/mixins/device"
import Layer from "./components/Layout/Layer.vue"

//import { Console } from "@capacitor/core"
//import { Plugins } from "@capacitor/core"
//import "@/styles/global.scss";
//const { PushNotifications } = Plugins

export default {
  mixins: [notifications, device],
  data() {
    return { internalInit: false }
  },
  components: { Layer },
  computed: {
    ...mapGetters(["loading", "device", "initiated"])
  },
  methods: {
    ...mapActions(["INIT_APP"]),
    ...mapMutations(["SET_SCREEN"]),
    mountedMethod() {
      //const deviceRta = await this.getDeviceInfo()

      const { platform } = this.device ? this.device : { platform: "" }
      console.log(
        "HEllo app, devide info ",
        this.device,
        window,
        window.innerHeight,
        window.innerWidth
      )
      // aldredy done
      this.SET_SCREEN({ width: window.innerWidth, height: window.innerHeight })
      if (platform != "web") {
        this.ngOnInit()
      }
    }
  },
  watch: {
    initiated() {
      console.log(this.internalInit, this.initiated)
      if (!this.internalInit) {
        this.mountedMethod()
      }
    }
  }

  /*   mountedOLD() {
    //alert("mounted")
    const { path, name } = this.$route
    console.log("PAPACHO AHOES SOMOS APP", this.$route, name)
    // paginas sueltas
    if (path.includes("contacto")) {
      // contacto
      console.log("in contact")
    } else if (path.includes("home") || path == "/") {
      console.log("in home")
    } else {
      this.INIT_APP().then((succ) => {
        console.log("after init", succ)

        if (succ) {
          //this.$router.push("/plants")
          this.ngOnInit()
          return true
        } else {
          this.$router.push("/login")
        }
      })
    }
  } */
}
</script>

<style lang="scss">
@import "@/styles/global.scss";
#app {
  font-family: "brown", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  position: relative;
  bottom: 0;
}
</style>
