<template>
  <div>
    <a :href="link" target="_blank" rel="noopener">
      <img
        class="align-self-center"
        src="/google-play-logo.png"
        aria-label="Loading..."
        width="100px"
      />
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      link: "https://play.google.com/store/apps/details?id=wseeds.ml"
    }
  }
}
</script>
