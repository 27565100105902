<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        class="
          position-fixed
          w-100
          bg-dark-purple-25
          d-flex
          align-items-center
          justify-content-center
          p-0-0-0-0
          z-8000
          layer-hei
        "
        v-if="user.role == 'demo'"
      >
        DEMO MODE
      </div>
      <div
        class="
          position-fixed
          w-100
          h-100
          bg-dark-purple-75
          d-flex
          align-items-center
          justify-content-center
          p-0-0-0-0
          z-8000
        "
        v-if="loading.active"
      >
        <inline-svg
          src="/rings.svg"
          width="180px"
          height="180px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { /* mapActions, */ mapGetters /* mapMutations  */ } from "vuex"
export default {
  computed: {
    ...mapGetters(["loading", "device", "user"])
  }
}
</script>

<style></style>
