import axios from "axios"
import accountApi from "@/api/account"
import utilsApi from "@/api/utils"
import Vue from "vue"
import { Device } from "@capacitor/device"
const getInitialState = () => {
  return {
    notifications: [],
    //token: null,
    status: "",
    alert_error: false,
    logged: false,
    auth: null,
    user: {
      active_boxes: null,
      name: null,
      phone: null,
      email: null,
      password: null,
      company: null,
      _id: null
    },
    initiated: false,
    objects: [],
    attributes: [],
    token_firebase: null,
    loading: {
      active: false
    },
    screen_size: {
      width: 0,
      height: 0
    },
    device: null
  }
}
const state = getInitialState()

const getters = {
  notifications: (state) => state.notifications,
  loading: (state) => state.loading,
  isAuthenticated: (state) => {
    //("isAut", !!state.auth, state.auth)
    return !!state.auth
  },
  user: (state) => state.user,
  wAUTH: (state) => state.auth,
  initiated: (state) => state.initiated,
  logged: (state) => state.logged,
  objects: (state) => state.objects,
  device: (state) => state.device,
  screen_size: (state) => state.screen_size
}

const actions = {
  async FILTER_NOTIFICATIONS({ commit }, filtersApp) {
    commit("LOADING", true, { root: true })
    const apiResponse = await utilsApi.filterNotifications(filtersApp)

    if (apiResponse) {
      commit("SET_NOTIFICATIONS", apiResponse.notifications)
      commit("LOADING", false, { root: true })
      return true
    }
    commit("LOADING", false, { root: true })
    return false
  },
  async getNotifications({ commit }) {
    const apiResponse = await utilsApi.getObjects("notifications")

    if (apiResponse) {
      commit("SET_NOTIFICATIONS", apiResponse.notifications)
    }
  },
  // updartes user and sent api request
  async updateUser({ commit }) {
    const { name, password, phone, email, company } = state.user
    const apiResponse = await accountApi.updateAccount({
      name,
      password,
      phone,
      email,
      company
    })
    if (apiResponse) {
      commit("SET_USER", apiResponse)
      return apiResponse
    } else {
      return null
    }
  },
  async INIT_APP({ commit, dispatch }) {
    if (!state.initiated) {
      commit("LOADING", true, { root: true })
      let preToken = localStorage.getItem("klausToken")
      const device = await Device.getInfo()
      commit("SET_DEVICE", device)
      //console.log("preten", preToken)
      if (preToken) {
        const apiResponse = await accountApi.getUserWithToken(preToken)
        if (apiResponse != null) {
          commit("LOG_IN_SUCCES", {
            user: apiResponse.user,
            auth_token: preToken
          })
          await dispatch("getNotifications")
          await dispatch("GET_OBJECTS", "measurements")
          commit("LOADING", false, { root: true })
          return true
        } else {
          //commit("LOG_IN_ERROR", {})
          commit("LOADING", false, { root: true })
          return false
        }
      } else {
        commit("LOADING", false, { root: true })
        commit("INICIATE", true, { root: true })
        return false
      }
    } else return false
  },
  AUTH_LOGOUT({ commit }) {
    return new Promise((resolve) => {
      commit("AUTH_LOGOUT")
      delete axios.defaults.headers.common["Authorization"]
      localStorage.removeItem("user-token") // clear your user's token from localstorage
      resolve()
    })
  },
  async AUTH_REQUEST({ dispatch }, account) {
    //console.log("LOGIN", JSON.stringify(account))
    const apiResponse = await accountApi.logIn(account)
    return dispatch("AUTH_REQUEST_local", apiResponse)
  },
  async AUTH_REQUEST_local({ commit, dispatch }, apiResponse) {
    //console.log("LOGIN", JSON.stringify(account))

    if (apiResponse) {
      const { auth_token } = apiResponse
      localStorage.setItem("klausToken", auth_token) // store the token in localstorage
      commit("AUTH_SUCCESS", auth_token)
      commit("LOG_IN_SUCCES", apiResponse)
      await dispatch("getNotifications")
      return true
    } else {
      commit("AUTH_ERROR")
      localStorage.removeItem("klausToken") // if the request fails, remove any possible user token if possible
      delete axios.defaults.headers.common["Authorization"]
      return false
    }
  },
  async LOG_IN({ commit }, data) {
    const response = await axios.$post("signin", data).catch(() => {
      //console.log("EEEEER", error)
      return null
    })

    //console.log("log ing", response)
    if (response !== null) {
      let { auth_token, user } = response
      //console.log(user, auth_token)
      commit("LOG_IN_SUCCES", { auth: auth_token, user })
    }

    return {
      auth_token: response ? response.auth_token : null,
      succes: null === response ? false : true
    }
  },
  async SIGN_UP({ dispatch, state }) {
    const apiResponse = await accountApi.signup(state.user)

    //console.log(apiResponse, apiResponse !== null)
    if (apiResponse !== null) {
      return dispatch("AUTH_REQUEST_local", apiResponse)
    } else {
      return false
    }
  },
  async SAVE_FORM({ commit, state }, { data, collection }) {
    //console.log("saveform", data, collection)
    let url = `${collection}/new`
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.auth}`
      }
    }

    const response = await axios.post(url, data, options).catch(() => {
      commit("ERROR", true)
      //console.log("EEEEER", err)
      return null
    })

    commit("UPDATE_USERNAME", response.account.name)
    commit("UPDATE_OBJ", response.collection)

    return {
      succes: null === response ? false : true
    }
  },

  async GET_OBJECTS({ commit }, collection) {
    const apiResponse = await utilsApi.getObjects(collection)
    //console.log("Data response", data)
    if (collection == "measurements") {
      if (apiResponse) {
        commit("SET_DATA", apiResponse["boxes"])
        commit("SET_BOXES", apiResponse["boxes"], { root: true })
        commit("SET_RANGE", apiResponse, { root: true })
      } else {
        commit("SET_DATA", [])
      }
    } else if (apiResponse) {
      commit("SET_DATA", apiResponse[collection])
    }
    return true
  },
  async UPDATE_NOTIFICATIONS_ON_OFF({ state }, body) {
    //console.log(state.user)
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: `Bearer ${state.token}`,
      }
    }
    const response = await axios.post(
      "boxes/setNotificationONoff",
      body,
      options
    )
    //console.log("Data response", response)
    return { response, user: state.user }
  },
  async UPDATE_NOTIFICATIONS({ state }, body) {
    //console.log(state.user)
    let options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
        //Authorization: `Bearer ${state.token}`,
      }
    }
    const response = await axios.post("boxes/setNotification", body, options)
    //console.log("Data response", response)
    return { response, user: state.user }
  },
  async SUCCES_IN({ commit }, token_firebase) {
    const info = await Device.getInfo()
    const id = await Device.getId()

    const apiRes = await accountApi.firebaseSetUp({
      token_firebase,
      name: info.name,
      uuid: id.uuid,
      platform: info.platform
    })
    if (apiRes) {
      commit("SET_TOKEN_FIREBASE", token_firebase)
    } else return null
  },
  LOG_OUT({ commit }) {
    commit("LOADING", true, { root: true })
    //delete axios.defaults.headers.common["Authorization"]
    localStorage.removeItem("klausToken")
    axios.defaults.headers.common["Authorization"] = null
    commit("RST", { root: true })
    commit("RESET_STATE")
    //commit("LOADING", false, { root: true })
  }
}

const mutations = {
  SET_SCREEN: (state, screenB) => {
    Vue.set(state, "screen_size", screenB)
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload
  },
  RESET_STATE: (state) => {
    Object.assign(state, getInitialState())
  },
  UPDATE_USER: (state, payload) => {
    Vue.set(state.user, payload.key, payload.value)
  },
  SET_USER: (state, payload) => {
    Vue.set(state, "user", payload)
  },
  LOADING: (state, payload) => {
    Vue.set(state.loading, "active", payload)
  },
  SET_TOKEN_FIREBASE: (state, token_firebase) => {
    state.token_firebase = token_firebase
  },
  AUTH_REQUEST: (state) => {
    state.status = "loading"
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = "success"
    state.token = token
  },
  AUTH_ERROR: ({ state }) => {
    state.status = "error"
  },
  UPDATE_OBJ: (state, objects) => {
    state.objects = objects
  },
  ERROR: (state, err) => {
    state.alert_error = err
  },
  SET_DEVICE: (state, device) => {
    // Vue set ??
    state.device = device
  },
  LOG_IN_SUCCES: (state, { auth_token, user }) => {
    state.logged = true
    //state.auth = auth_token
    Vue.set(state, "auth", auth_token)
    //state.user = user
    Vue.set(state, "user", user)
    state.initiated = true
  },
  SET_DATA: (state, data) => {
    state.objects = data
  },
  SET_ATTRIBUTES: (state, attributes) => {
    state.attributes = attributes
  },
  INICIATE: (state, boolean) => {
    state.initiated = boolean
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
