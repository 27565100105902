<template>
  <div class="py-5 px-2 mt-5 d-flex flex-column">
    <button
      class="
        my-3
        mx-3
        h-3-5-r
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        justify-content-between
        text-grey-medium
        w-auto
      "
      @click="$router.push({ query: { mode: 'view' } })"
    >
      <span class="mx-3 align-self-center">Perfil de usuario</span>
      <div class="align-self-center">
        <inline-svg
          class="align-self-center"
          src="/flecha-ladoder.svg"
          width="30px"
          height="30px"
          fill="white"
          aria-label="Loading..."
        />
      </div>
    </button>

    <button
      @click="logOut"
      class="
        my-3
        mx-3
        h-max-content h-3-5-r
        rounded
        bg-white
        shadow-sm
        pointer
        d-flex
        justify-content-between
        text-grey-medium
        w-auto
      "
    >
      <span class="mx-3 align-self-center">Cerrar sesión</span>
      <div class="align-self-center"></div>
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  methods: {
    ...mapActions(["LOG_OUT"]),
    logOut() {
      this.LOG_OUT()
      this.$router.push({ name: "Login" })
    }
  }
}
</script>

<style></style>
