import { PushNotifications } from "@capacitor/push-notifications"
export default {
  methods: {
    async ngOnInit() {
      //console.log("Initializing HomePage")
      // alert("INIT", PushNotifications)
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting requestPermissions
      PushNotifications.requestPermissions().then((result) => {
        console.log("ResultGranter", JSON.stringify(result))
        /* alert(
          "ResultGranter",
          JSON.stringify(result),
          result.granted ? true : false,
          result.receive ? true : false,
          "end"
        ) */
        if (result.granted || result.receive) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()
        } else {
          // Show some error
        }
      })

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token) => {
        // alert("Push registration success, token: " + token.value)
        this.$store.dispatch("SUCCES_IN", token.value)
      })

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error) => {
        alert("Error on registration: " + JSON.stringify(error))
      })

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          alert("Push received: " + JSON.stringify(notification))
        }
      )

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          alert("Push action performed: " + JSON.stringify(notification))
        }
      )
    }
  }
}
