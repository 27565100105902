const dropzoneSettings = {
  url: "https://api.cloudinary.com/v1_1/dzneqacw6/image/upload",
  url_videos: "https://api.cloudinary.com/v1_1/dzneqacw6/video/upload",
  api_key: 943133665768727,
  upload_preset: "yetv2mty",
  videoFileTypes: "video/*",
  photoFileTypes: "image/*",
  thumbnailWidth: 150,
  maxFilesize: 140,
  parallelUploads: 4
}
const apiSettings = {
  //url: "https://wseeds.klaus.market"
  //url: "https://api.knnabis.co",
  //url: "http://localhost:49006"
  url: "https://back.wseeds.co"
}

const firebasePublicKey =
  "BH0CgXC6GXL9EVd0NW8HrqumATYnP1uPrhSPIYJEwXRRiGO4H9M6l36RreZvnkGmqeNqTgyRsazTWhmagOSSW1Q"
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCAQHn_ofxsKLlq1Pu5EBQ9dfxMJ1212iM",

  authDomain: "wapp-986e0.firebaseapp.com",
  databaseURL: "https://wapp-986e0.firebaseio.com",
  projectId: "wapp-986e0",
  storageBucket: "wapp-986e0.appspot.com",
  messagingSenderId: "196925719333",
  appId: "1:196925719333:web:52d1393c04c689b40cd0cc",
  measurementId: "G-T9BC07ZWS3"
}
export default {
  dropzoneSettings,
  apiSettings,
  firebaseConfig,
  firebasePublicKey
}
