import accountApi from "@/api/account"
import Vue from "vue"

const getInitialState = () => {
  return {
    initiated: false,
    report: {},
    geoBoxes: []
  }
}
const state = getInitialState()

const getters = {
  report: (state) => state.report,
  geoBoxes: (state) => state.geoBoxes
}

const actions = {
  async INIT_STORE({ state, commit }) {
    if (!state.initiated) {
      commit("LOADING", true, { root: true })
      const apiResponse1 = await accountApi.getReport()
      const apiResponse2 = await accountApi.getGeoListing()
      if (apiResponse1 != null && apiResponse2 != null) {
        commit("SET_REPORT", apiResponse1)
        commit("SET_GEO_BOXES", apiResponse2)
        commit("LOADING", false, { root: true })
        return true
      } else {
        //commit("LOG_IN_ERROR", {})
        commit("LOADING", false, { root: true })
        return false
      }
    } else return false
  },
  async SAVE_REPORT({ state, commit }) {
    commit("LOADING", true, { root: true })
    const apiResponse = await accountApi.updateReport(state.report)
    if (apiResponse) {
      commit("LOADING", false, { root: true })
      return true
    }
    return false
  }
}

const mutations = {
  SET_REPORT: (state, payload) => {
    Vue.set(state, "report", payload.report)
    state.initiated = true
  },
  SET_GEO_BOXES: (state, payload) => {
    Vue.set(state, "geoBoxes", payload.boxes)
    state.initiated = true
  },
  UPDATE_REPORT: (state, payload) => {
    Vue.set(state.report, payload.key, payload.value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
