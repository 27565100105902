<template>
  <div
    class="position-absolute d-flex flex-column"
    :style="{
      'overflow-x': 'hidden',
      'min-height': '100vh',
      width: '100vw !important'
    }"
  >
    Hello admin
    <admin-view v-if="$route.params.viewA"></admin-view>
    <div v-else>
      <button
        @click="
          $router.push({
            name: 'Admin',
            params: { viewA: 'map' },
            query: {}
          })
        "
        class="
          my-3
          mx-3
          h-max-content h-3-5-r
          rounded
          bg-white
          shadow-sm
          pointer
          d-flex
          justify-content-between
          text-grey-medium
          w-auto
        "
      >
        <span class="mx-3 align-self-center">Geo Boxes</span>
        <div class="align-self-center"></div>
      </button>
      <button
        @click="
          $router.push({
            name: 'Admin',
            params: { viewA: 'editor' },
            query: {}
          })
        "
        class="
          my-3
          mx-3
          h-max-content h-3-5-r
          rounded
          bg-white
          shadow-sm
          pointer
          d-flex
          justify-content-between
          text-grey-medium
          w-auto
        "
      >
        <span class="mx-3 align-self-center">Report Editor </span>
        <div class="align-self-center"></div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import AdminView from "@/components/Admin/AdminView"
export default {
  components: {
    AdminView
  },
  methods: {
    ...mapActions("admin", ["INIT_STORE"])
  },
  mounted() {
    this.INIT_STORE()
  }
}
</script>

<style></style>
