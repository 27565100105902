var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-absolute d-flex flex-column",style:({
    'overflow-x': 'hidden',
    'min-height': '100vh',
    width: '100vw !important'
  })},[_vm._v(" Hello admin "),(_vm.$route.params.viewA)?_c('admin-view'):_c('div',[_c('button',{staticClass:"\n        my-3\n        mx-3\n        h-max-content h-3-5-r\n        rounded\n        bg-white\n        shadow-sm\n        pointer\n        d-flex\n        justify-content-between\n        text-grey-medium\n        w-auto\n      ",on:{"click":function($event){return _vm.$router.push({
          name: 'Admin',
          params: { viewA: 'map' },
          query: {}
        })}}},[_c('span',{staticClass:"mx-3 align-self-center"},[_vm._v("Geo Boxes")]),_c('div',{staticClass:"align-self-center"})]),_c('button',{staticClass:"\n        my-3\n        mx-3\n        h-max-content h-3-5-r\n        rounded\n        bg-white\n        shadow-sm\n        pointer\n        d-flex\n        justify-content-between\n        text-grey-medium\n        w-auto\n      ",on:{"click":function($event){return _vm.$router.push({
          name: 'Admin',
          params: { viewA: 'editor' },
          query: {}
        })}}},[_c('span',{staticClass:"mx-3 align-self-center"},[_vm._v("Report Editor ")]),_c('div',{staticClass:"align-self-center"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }